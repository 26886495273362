@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  letter-spacing: -0.02em;
}

/* Update heading styles */
h1, h2, h3 {
  letter-spacing: -0.03em;
  font-weight: 600;
}

/* Increase spacing between sections */
.section {
  margin: 120px 0;
  padding: 0 24px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .responsive-text {
    display: block;
  }
}

.arrow-button:hover {
  transform: translateY(-50%) scale(1.1);
}

.slide-in-text {
  animation: slideInFromLeft 0.8s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  animation-delay: 0s;
}
@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0.8) rotate(10deg); /* Start at larger scale */
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(10deg);
  }
}

.growing-logo {
  animation: growIn 1s ease-out forwards;
  animation-delay: 0s;  
  opacity: 0;
  transform: scale(0) rotate(10deg);
  animation-fill-mode: forwards;
}
@keyframes growInNoRotate {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.growing-logo2 {
  animation: growInNoRotate 1s ease-out forwards;
  animation-delay: 0s; /* Adjust this to match the duration of your text animation */
  opacity: 0; /* Start invisible */
  transform: scale(0); /* Start small */
}




@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.tagline {
  overflow: hidden;
}

.line {
  opacity: 0;
  transform: translateX(-100px);
  animation: slideIn 1.5s ease-out forwards;
}

.line-0 { animation-delay: 0.5s; }
.line-1 { animation-delay: 1s; }
.line-2 { animation-delay: 1.5s; }
.line-3 { animation-delay: 2s; }

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Reduced distance */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.highlight {
  font-size: 1.6em;
  font-style: italic;
  background: linear-gradient(45deg, #000000, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rotating-logo {
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Add these new rules */
.tagline.animate-once .line {
  animation: none;
  opacity: 1;
  transform: translateX(0);
}

.tagline.animate-once .line-0,
.tagline.animate-once .line-1,
.tagline.animate-once .line-2,
.tagline.animate-once .line-3 {
  animation-delay: 0s;
}

.sparkle {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #FFD700;
  pointer-events: none;
  animation: sparkle 1s ease-in-out;
}

@keyframes sparkle {
  0% { transform: scale(0); }
  50% { transform: scale(1); }
  100% { transform: scale(0); opacity: 0; }
}
body {
  margin: 0;
  padding: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #e0f0ff;
  overflow-x: hidden;
  position: relative;
}

body::before, body::after {
  content: '';
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  z-index: -1;
  background: 
    radial-gradient(circle at 20% 20%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 20%),
    radial-gradient(circle at 80% 30%, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 15%),
    radial-gradient(circle at 40% 70%, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 18%),
    radial-gradient(circle at 70% 80%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 22%),
    radial-gradient(ellipse at center, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 70%);
  background-size: 200% 200%;
  animation: moveCloud 50s ease-in-out infinite alternate;
  filter: blur(2px);
  opacity: 1;
}

body::after {
  animation-duration: 70s;
  animation-delay: -30s;
  opacity: 1;
  background: 
    radial-gradient(circle at 60% 10%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 18%),
    radial-gradient(circle at 10% 50%, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 20%),
    radial-gradient(circle at 90% 60%, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 15%),
    radial-gradient(circle at 30% 90%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 22%);
}

@keyframes moveCloud {
  0% {
    transform: translate(10%, 10%);
  }
  100% {
    transform: translate(-10%, -10%);
  }
}

#root {
  min-height: 100vh;
  background: transparent;
}


/* Modal Container */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* style.css or any other css file */
.confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001; /* Higher z-index to appear on top of everything */
  pointer-events: none; /* Ensure it doesn't block interactions */
}

.quiz-overlay {
  position: relative;
  z-index: 1000; /* Ensure this is lower than the confetti canvas */
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  pointer-events: none;
}

.code-block-container {
  background-color: white;
  border: 2px solid #00ffff; /* Neon blue color */
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5); /* Neon glow effect */
}

/* Modal Content */
.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  text-align: center;
}

/* Close Button */
.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


code {
  font-family: 'M PLUS Rounded 1c', monospace;
}

.startButton:hover {
  transform: scale(1.1);

}

html {
  scroll-behavior: smooth;
}
.katex-display {
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.math-block {
  overflow-x: auto;
  margin: 1em 0;
}

.quiz-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.quiz-content {
  padding-right: 20px;
}

.quiz-summary {
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
}

.summary-content {
  padding-right: 10px;
}

.summary-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.summary-item:last-child {
  border-bottom: none;
}

.math-inline {
  display: inline-block;
  text-align: left;

}

.math-content {
  overflow-x: auto;
}
.quiz-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.quiz-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
}

.choice-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}



.choice-button.correct {
  background-color: #90EE90;
}

.choice-button.incorrect {
  background-color: #FFA07A;
}



.conversation-container {
  scroll-behavior: smooth;
}

.toggle.red-pill .react-toggle-track {
  background-color: red;
}

.toggle.blue-pill .react-toggle-track {
  background-color: blue;
}
.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.feature-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.feature-emoji {
  font-size: 20px;
  margin-right: 10px;
}


  /* In your CSS file or in a styled-components block */
  .spinner {
    border: 10px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .growing-logo.animate-once {
    animation: none;
    opacity: 1;
    transform: scale(1) rotate(10deg);
  }
  
  .slide-in-text.animate-once {
    animation: none;
    opacity: 1;
    transform: translateX(0);
  }
  
  .star-1.animate-once, .star-2.animate-once {
    animation: none;
    opacity: 1;
    transform: translateX(0) rotate(0deg);
  }