.code-block {
  background-color: #ffffff; /* Light blue background */
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  position: relative;
  border: 2px solid #0000FF; /* Blue border */
}

.code-block pre {
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  text-align: left; /* Left-justify the code */
}

.code-block button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  border: 1px solid #0000FF; /* Blue button border */
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  color: #0000FF; /* Blue button text */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  overflow: hidden;
}

.loading-logo {
  width: 120px;
  height: 120px;
  animation: pulse 2s ease-in-out infinite;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  animation: float 5s infinite ease-in-out;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-100px); }
  100% { transform: translateY(0px); }
}

.instagram-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 177.77%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
  overflow: hidden;
  margin-right: 10px;
}

.instagram-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.response-container {
  width: 100%; /* Takes up full width of the parent container */
  max-width: 800px; /* Adjust this value based on your desired maximum width */
  min-width: 300px; /* Adjust this value based on your desired minimum width */
}

@media (max-width: 600px) {
  .response-container {
    max-width: 100%; /* Takes up full width on smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}