.flashcards-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Inter', sans-serif;
    background: linear-gradient(135deg, #f0f7ff, #f9fafb);
    min-height: 100vh;
    position: relative;
  }
  
  .new-card-form {
    background: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #374151;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.2s;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #3b82f6;
    outline: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background: #3b82f6;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submit-button:hover {
    background: #2563eb;
  }
  
  .conversations-section {
    margin-top: 40px;
    padding: 20px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .conversations-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .conversation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #f8fafc;
    border-radius: 8px;
    transition: background-color 0.2s;
  }
  
  .conversation-item:hover {
    background: #f1f5f9;
  }
  
  .conversation-title {
    font-size: 16px;
    color: #374151;
    flex: 1;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .add-to-flashcards-button {
    padding: 8px 16px;
    background: #3b82f6;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .add-to-flashcards-button:hover {
    background: #2563eb;
  }
  
  .add-to-flashcards-button:active {
    transform: scale(0.98);
  }
  
  .generate-button {
    background: #3b82f6;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .generate-button:hover {
    background: #2563eb;
  }
  
  .flashcards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin: 40px 0;
  }
  
  .flashcard {
    width: 400px;
    height: 250px;
    perspective: 1000px;
    margin: 0 auto;
  }
  
  .flashcard-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
  }
  
  .flashcard-inner.flipped {
    transform: rotateY(180deg);
  }
  
  .flashcard-front,
  .flashcard-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .flashcard-back {
    transform: rotateY(180deg);
    background: #f8fafc;
  }
  
  .flashcard-front p,
  .flashcard-back p {
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    word-wrap: break-word;
    overflow-y: auto;
    max-height: 150px;
  }
  
  .card-actions {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 2;
    opacity: 0; /* Hide buttons by default */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
  }
  
  .flashcard-front:hover .card-actions {
    opacity: 1; /* Show buttons on hover */
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .edit-button {
    background: #3b82f6;
    color: white;
  }
  
  .delete-button {
    background: #ef4444;
    color: white;
  }
  
  .edit-button:hover,
  .delete-button:hover {
    transform: scale(1.05);
  }
  
  .edit-button:active,
  .delete-button:active {
    transform: scale(0.95);
  }
  
  .section-title {
    color: #1f2937;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .flashcards-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 0;
    padding: 0 60px;
  }
  
  .nav-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3b82f6;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    flex-shrink: 0;
  }
  
  .nav-button:hover {
    background: #2563eb;
  }
  
  .card-counter {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #6b7280;
  }
  
  /* Make carousel responsive */
  @media (max-width: 768px) {
    .flashcard {
      width: 300px;
    }
    
    .nav-button {
      width: 32px;
      height: 32px;
    }
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h3 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .form-group textarea {
    height: 120px;
    resize: none;
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .no-cards {
    text-align: center;
    padding: 40px;
    color: #6b7280;
  }
  
  /* Edit form styles */
  .edit-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .edit-form input,
  .edit-form textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .edit-form textarea {
    height: 120px;
    resize: none;
  }
  
  .edit-form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: auto;
  }
  
  .edit-form-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .edit-form-actions button:first-child {
    background: #3b82f6;
    color: white;
  }
  
  .edit-form-actions button:last-child {
    background: #e5e7eb;
    color: #374151;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .add-card-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3b82f6;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  
  .add-card-button:hover {
    background: #2563eb;
  }
  
  .floating-add-button {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    color: white;
    border: none;
    font-size: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 
      0 12px 24px -8px rgba(59, 130, 246, 0.4),
      0 0 0 1px rgba(59, 130, 246, 0.1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
  }
  
  .floating-add-button:hover {
    transform: scale(1.05);
    box-shadow: 
      0 20px 30px -10px rgba(59, 130, 246, 0.4),
      0 0 0 1px rgba(59, 130, 246, 0.1);
  }
  
  .floating-add-button:active {
    transform: scale(0.95);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .modal-content {
    background: white;
    padding: 30px;
    border-radius: 15px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .no-cards {
    text-align: center;
    padding: 40px;
    color: #6b7280;
  }
  
  /* Edit form styles */
  .edit-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .edit-form input,
  .edit-form textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .edit-form textarea {
    height: 120px;
    resize: none;
  }
  
  .edit-form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: auto;
  }
  
  .edit-form-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .edit-form-actions button:first-child {
    background: #3b82f6;
    color: white;
  }
  
  .edit-form-actions button:last-child {
    background: #e5e7eb;
    color: #374151;
  }
  
  .deck-selection {
    margin: 40px auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .deck-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
    margin-top: 30px;
  }
  
  .deck-button {
    height: 180px;
    border: none;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #1f2937;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 25px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06),
      0 0 0 1px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
  }
  
  .deck-button:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04),
      0 0 0 1px rgba(0, 0, 0, 0.05);
  }
  
  .deck-button.selected {
    background: linear-gradient(145deg, #f0f7ff, #e6f0ff);
    border: 2px solid #3b82f6;
    box-shadow: 
      0 0 0 4px rgba(59, 130, 246, 0.1),
      0 12px 24px -8px rgba(59, 130, 246, 0.2);
  }
  
  .deck-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    position: relative;
    z-index: 2;
  }
  
  .deck-info {
    font-size: 0.95rem;
    color: #6b7280;
    position: relative;
    z-index: 2;
  }
  
  .deck-preview {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 140px;
    height: 90px;
    background: linear-gradient(135deg, #f3f4f6, #e5e7eb);
    border-radius: 12px;
    transform: rotate(-8deg);
    box-shadow: 
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 1;
  }
  
  .deck-preview::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(135deg, #f9fafb, #f3f4f6);
    border-radius: 12px;
    transform: rotate(-4deg);
    z-index: -1;
  }
  
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    text-decoration: none;
  }
  
  .back-button img {
    height: 50px;
    width: auto;
  }