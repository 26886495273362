.my-super-answers {
    padding: 10px;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-button {
    font-size: 0.9em;
    padding: 8px 16px;
    background: white;
    color: black;
    border: 1px solid #002fa7;
    cursor: pointer;
  }
  
  .tab-button:first-child {
    border-radius: 15px 0 0 15px;
  }
  
  .tab-button:last-child {
    border-radius: 0 15px 15px 0;
  }
  
  .tab-button.active {
    background: #002fa7;
    color: white;
  }
  
  .search-input {
    width: 100%;
    padding: 8px;
    font-size: 0.9em;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .items-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .item {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }
  
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .options-button {
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .item-date {
    font-size: 0.8em;
    color: gray;
  }
  
  .item-options {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .item-options button {
    font-size: 0.8em;
    padding: 5px;
  }
  
  .rename-form {
    display: flex;
    gap: 5px;
  }
  
  .rename-form input {
    flex-grow: 1;
  }
  
  .item-button {
    width: 100%;
    font-size: 0.9em;
    padding: 8px;
    background: white;
    color: black;
    border: 1px solid black;
    border-radius: 15px;
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .song-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .song-title {
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .audio-player {
    width: 100%;
    max-width: 100%;
  }
  
  @media (max-width: 480px) {
    .tab-button {
      font-size: 0.8em;
      padding: 6px 12px;
    }
  
    .search-input {
      font-size: 0.8em;
    }
  
    .item-button {
      font-size: 0.8em;
    }
  
    .song-title {
      font-size: 0.8em;
    }
  }