.careers-container {
  min-height: 100vh;
  background-color: #f5f5f7;
  font-family: 'Poppins', sans-serif;
}

.careers-hero {
  background-color: transparent;
  color: #333;
  padding: 20px 20px 30px;
  text-align: center;
  position: relative;
}

.careers-logo {
  height: 120px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.careers-logo:hover {
  transform: scale(1.05);
}

.careers-hero h1 {
  font-size: clamp(2.5em, 6vw, 4em);
  font-weight: 800;
  margin: 0;
  font-style: italic;
  letter-spacing: -0.02em;
  color: #333;
}

.hero-subtitle {
  font-size: clamp(1.2em, 3vw, 1.8em);
  font-weight: 500;
  margin-top: 5px;
  color: #666;
}

.careers-content {
  max-width: 1200px;
  margin: 20px auto 0;
  padding: 0 20px 80px;
  position: relative;
  z-index: 1;
}

.careers-content h2 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
  color: #333;
}

.positions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.position-card {
  background: #fff;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.position-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.position-header {
  padding: 30px;
  border-bottom: 1px solid #eee;
}

.position-header h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #333;
}

.position-type {
  display: inline-block;
  padding: 6px 12px;
  background: rgba(65, 105, 225, 0.1);
  color: rgb(65, 105, 225);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.position-details {
  padding: 30px;
}

.compensation {
  color: #007aff;
  font-weight: 500;
  margin-bottom: 20px;
}

.responsibilities h4,
.qualifications h4 {
  font-size: 18px;
  margin: 20px 0 15px 0;
  color: #333;
  font-weight: 600;
}

.position-details ul {
  padding-left: 20px;
  margin: 0;
}

.position-details li {
  margin-bottom: 10px;
  color: #666;
  line-height: 1.4;
}

.apply-button {
  width: 100%;
  padding: 15px 30px;
  margin-top: 30px;
  background-color: rgb(65, 105, 225);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

.apply-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .careers-hero h1 {
    font-size: 40px;
  }
  
  .hero-subtitle {
    font-size: 20px;
  }
  
  .careers-content h2 {
    font-size: 32px;
  }
  
  .positions-grid {
    grid-template-columns: 1fr;
  }
} 