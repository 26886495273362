.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .spinner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }